// import SwiperArticles from '../../Swiper/Swiper';
import SwiperArticle from '../../Swiper/SwiperArticle';
import './Article.css';

function Articles() {
  return (
    <>
      <div className="main__articles">
        <div className="main-title-article">
          <p>Recommended articles</p>
          <h2>
            Everything about dry cleaning of carpets, sofas, armchairs, cars,
            mattresses, strollers, corner sofas...
          </h2>
        </div>
        <SwiperArticle />
      </div>
    </>
  );
}
export default Articles;
