import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi9';
import { UslugiListEn } from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2>Washing curtains, curtains and blinds in Warsaw</h2>
        <p>
          Washing curtains and blinds is an often overlooked activity in keeping
          our homes clean. However, this is a very important step that we should
          not ignore. Washing these window equipment elements is not only a
          matter of aesthetics, but above all health. Below are some reasons why
          washing curtains and blinds is so important.
        </p>
        <p>
          First of all, curtains, curtains and blinds collect a lot of dust,
          pollen and other pollutants that can cause respiratory problems. Many
          people suffer from allergies and asthma, and such pollutants can
          negatively affect their health. Washing these items regularly can help
          remove pollutants and improve the air quality in our homes.
        </p>
        <p>
          Secondly, curtains, curtains and blinds are exposed to pollution from
          the environment. These may include, among others: exhaust fumes,
          cigarette smoke or odors from the kitchen. If we do not wash these
          elements regularly, these odors will remain on the curtains, curtains
          and blinds, which may negatively affect the air quality in our homes.
        </p>
        <p>
          Third, washing curtains and blinds can help maintain their original
          appearance. These window furnishings are often exposed to sunlight,
          which can cause colors to fade and fabrics to be damaged. Washing them
          regularly can help maintain their colors and textures longer.
        </p>
        <p>
          It is also worth noting that washing curtains and blinds is beneficial
          not only for our health, but also for our wallets. If we wash these
          elements regularly, they will be more durable and we will not have to
          replace them with new ones so often.
        </p>
        <p>
          To sum up, washing curtains and blinds is an important activity that
          we should not skip in order to keep our homes clean. It helps remove
          pollutants, improve air quality and maintain the original appearance
          of fabrics. Washing these items regularly is beneficial both for our
          health and our wallets.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiListEn />
        </div>
      </div>
    </div>
  );
}
function Usluga9() {
  const obj = {
    mainLang: 'En',
    path: '/usluga9',
    langP: 'English',
    langEng: 'Poland',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga9;
