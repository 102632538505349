import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi7';
import { UslugiListEn } from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2> Leather upholstery cleaning in Warsaw</h2>
        <p>
          Natural leather is an expensive material that must be handled very
          carefully. Despite its durability, it is subject to periodic
          contamination, and microcracks and scratches may also appear on the
          surface.
        </p>
        <h3>Stages of comprehensive cleaning of leather furniture:</h3>
        <ul>
          <li>Perform diagnostics and check the condition of the material;</li>
          <li>Remove dust, dirt and plaque with special cleaning products;</li>
          <li>
            Use stain removers to clean the material from dirt and stains;
          </li>
          <li>
            Use a cleaning agent to restore the original color and smell of the
            material;
          </li>
          <li>
            Leather furniture renovation is done using special, delicate
            products.
          </li>
        </ul>
        <p>
          As a result of such comprehensive work at home, leather furniture will
          restore its original appearance, aroma and cleanliness.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiListEn />
        </div>
      </div>
    </div>
  );
}
function Usluga7() {
  const obj = {
    mainLang: 'En',
    path: '/usluga7',
    langP: 'English',
    langEng: 'Poland',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga7;
