// import SwiperArticles from '../../Swiper/Swiper';
import SwiperArticle from '../../Swiper/SwiperArticle';
import './Article.css';

function Articles() {
  return (
    <>
      <div className="main__articles">
        <div className="main-title-article">
          <p>Polecane artykuły</p>
          <h2>
            Wszystko o praniu chemicznym dywanów, sof, foteli, samochodów,
            materacy, wózków, narożników...
          </h2>
        </div>
        <SwiperArticle />
      </div>
    </>
  );
}
export default Articles;
