import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import LinkSocial from '../../1_Header/SocialLinks/LinkSocial';
import foto from '../../../img/contact/plan.jpg';

import './Contact.css';
import WhatsApp from '../../WhatsUp/WhatsappLink';
import MyPhone from '../../../UI/MyPhone/MyPhone';
import MyEmail from '../../../UI/MyEmail/MyEmail';
import FormaZayavkaContact from '../../../UI/Forma/Forma_zayavka';

function ContactTable() {
  return (
    <div className="contact">
      <div className="contacts-title">
        <h1>KONTAKT</h1>
      </div>
      <div className="container contacts__container">
        <div className="contacts__item">
          <div className="contacts__item-phone">
            <p>Numer telefonu:</p>
            <MyPhone
              classphone="contac="
              icons=""
              classphonelink="phone-contact"
            />
          </div>
          <div className="contacts__item-phone">
            {/* <p>Email:</p> */}
            <MyEmail icons="" classnamelink="email-contact"></MyEmail>
          </div>

          <div className="contacts__item-social">
            <p>Jesteśmy w sieciach społecznościowych:</p>
            <div className="contacts__item-social-links">
              <LinkSocial />
            </div>
          </div>
          <div className="contacts__item-praca">
            <img src={foto} alt="foto" />
            <p>Pracujemy 7 dni w tygodniu, w godzinach 6:00-22:00,</p>
            <p>a jeżeli wymaga tego sytuacja również w nocy.</p>
            <p>Działamy na terenie Warszawy i okolic.</p>
          </div>
          <div className="adress">Warszawa, Polska</div>
          <FormaZayavkaContact />
        </div>
      </div>
    </div>
  );
}
function Contact() {
  const obj = {
    mainLang: 'PL',
    path: '/en/contact',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <div className="body_wrapper">
      <Header obj={obj} />

      <ContactTable />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Contact;
