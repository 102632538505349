import { useProject } from '../../tools/ProviderContext';
import { FormaZayavkaEnglish } from '../../UI/Forma/Forma_zayavka';
import './linkForma.css';

function LinkForma() {
  const { isForma, closeForma } = useProject();
  return (
    <>
      <div
        onClick={closeForma}
        className={isForma ? 'backdrop-form' : 'hidden'}
      >
        <div className="get-forma">
          <FormaZayavkaEnglish closeForma={closeForma} />
        </div>
      </div>
    </>
  );
}

export default LinkForma;
