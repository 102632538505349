import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi3';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2> Pranie foteli w Warszawie</h2>
        <p>
          Czyszczenie chemiczne fotela to ważny etap pielęgnacji mebli, który
          pomaga zachować ich schludny wygląd i przedłużyć ich żywotność.
          Podczas codziennego użytkowania fotele gromadzą kurz, plamy i
          bakterie, co nie tylko psuje ich wygląd, ale także może wywoływać
          reakcje alergiczne. Profesjonalne czyszczenie chemiczne skutecznie
          usuwa zanieczyszczenia przy użyciu specjalnych środków, które nie
          niszczą materiału, pozostawiając meble świeże i czyste.
        </p>
        <h3>Proces czyszczenia chemicznego składa się z kilku etapów:</h3>
        <p>
          Proces czyszczenia chemicznego fotela składa się z kilku starannie
          przeprowadzonych etapów, aby zapewnić jak najlepsze efekty. Najpierw
          fotel poddaje się wstępnemu oczyszczeniu, które usuwa większe
          zabrudzenia, takie jak kurz i luźne zanieczyszczenia. Następnie na
          powierzchnię tapicerki aplikuje się specjalne środki czyszczące, które
          są dobierane w zależności od rodzaju materiału – inne preparaty
          stosuje się do tkanin, a inne do skóry czy zamszu. Te środki wnikają
          głęboko w tkaninę, rozpuszczając plamy i usuwając bakterie. Po tym
          etapie nadmiar wilgoci oraz pozostałości brudu są skutecznie usuwane
          przy pomocy specjalnych urządzeń, dzięki czemu fotel nie tylko wygląda
          świeżo, ale również szybko schnie.
        </p>
        <p>
          {' '}
          Rezultatem takiego czyszczenia jest nie tylko estetyczne odświeżenie
          mebli, ale także poprawa higieny w przestrzeni mieszkalnej.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga3() {
  const obj = {
    mainLang: 'PL',
    path: '/en/usluga3',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga3;
