import './topheader.css';
import MyEmail from '../../../UI/MyEmail/MyEmail';
import MyPhone from '../../../UI/MyPhone/MyPhone';

import ButtomsLang from '../../../UI/Buttons/Buttons';
import { useState } from 'react';
import LinkSocial from '../../../components/1_Header/SocialLinks/LinkSocial';

function TopHeader({ obj }) {
  const [isTopHeader, setTopHeader] = useState(true);
  window.addEventListener('scroll', () => {
    if (window.scrollY >= 50) {
      setTopHeader(false);
    } else if (window.scrollY < 50) {
      setTopHeader(true);
    }
  });
  return (
    <>
      <div className={isTopHeader ? 'header-top' : 'header-top-fixed'}>
        <div className="container">
          <div className="header-top__wrapper">
            <div className="header-top__contacts">
              <MyEmail>
                {' '}
                <span>samurai.dry.cleaning@gmail.com</span>{' '}
              </MyEmail>
              <MyPhone />
            </div>
            <div className="wrapper__social-lang">
              <div className="header-top__social">
                <LinkSocial />
              </div>
              <div className="header-top__lang">
                <ButtomsLang obj={obj} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopHeader;
