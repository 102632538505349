import { useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MyPhone from '../../UI/MyPhone/MyPhone';

import './Footer.css';

import MyEmail from '../../UI/MyEmail/MyEmail';
import Schedule from '../1_Header/Schedule/Schedule';
import LinkSocial from '../../components/1_Header/SocialLinks/LinkSocial';

function FooterMenu() {
  const [isScrollUp, setScrollUp] = useState(true);

  useEffect(() => {
    if (isScrollUp) {
      window.scroll({ top: 0, behavior: 'smooth' });
      setScrollUp(false);
    }
  }, [isScrollUp]);

  return (
    <nav className="footer-menu">
      <ul className="footer__menu__list">
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/en/main"
            className="footer__menu__link"
          >
            Main
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/en/about"
            className="footer__menu__link"
          >
            About us
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/en/usluga"
            className="footer__menu__link"
          >
            Services
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/en/price"
            className="footer__menu__link"
          >
            Price
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/en/article1"
            className="footer__menu__link"
          >
            Blog
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/en/contact"
            className="footer__menu__link"
          >
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
}

function Footer() {
  const [isHeader, setIsHeader] = useState(false);

  useLayoutEffect(() => {
    if (window.innerWidth < 600) {
      setIsHeader(true);
    } else {
      setIsHeader(false);
    }
  }, [setIsHeader]);
  return (
    <>
      <div className="footer">
        <div className="container footer__container">
          <div className="footer__main">
            <div className="footer__menu">
              <FooterMenu />
            </div>

            {isHeader ? (
              <>
                <div className="footer__social-links">
                  <LinkSocial close={''} />
                  <div className="footer-phone header__info">
                    <MyPhone classphonelink="footer__phone-link " />
                    <MyEmail></MyEmail>
                    <Schedule />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="footer__title_phone">
                  <div className="footer-phone header__info">
                    <MyPhone classphonelink="footer__phone-link " />
                    <MyEmail></MyEmail>
                    <Schedule />
                  </div>
                </div>
                <div className="footer__social-links">
                  <LinkSocial close={''} />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="footer__down">
          <h3> Cleaning furniture | Czysty Dom Waw | 2024 </h3>
        </div>
      </div>
    </>
  );
}
export default Footer;
