import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi5';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2>Pranie narożników w Warszawie</h2>
        <p>
          Pranie narożników to proces, który wymaga odpowiedniej techniki i
          narzędzi, aby skutecznie usunąć brud, plamy i bakterie. Właściwe
          czyszczenie narożników nie tylko poprawia ich wygląd, ale także
          przedłuża ich żywotność. Ważne jest, aby regularnie dbać o
          tapicerowane meble, zwłaszcza te, które są intensywnie użytkowane.
        </p>
        <p>
          Przed przystąpieniem do prania narożnika, warto sprawdzić, z jakiego
          materiału jest wykonany. Niektóre tkaniny wymagają specjalistycznych
          środków czyszczących, a inne mogą być bardziej odporne na wilgoć.
          Należy także pamiętać o regularnym odkurzaniu, aby usunąć kurz i
          zanieczyszczenia powierzchniowe.
        </p>
        <p>
          Profesjonalne usługi prania narożników często wykorzystują metody
          parowe lub specjalne ekstraktory, które pozwalają na głębokie
          oczyszczenie tkaniny bez ryzyka jej uszkodzenia. Regularne korzystanie
          z takich usług może znacząco poprawić higienę w domu i estetykę mebli.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga5() {
  const obj = {
    mainLang: 'PL',
    path: '/en/usluga5',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga5;
