import { Link } from 'react-router-dom';
import { FaTiktok } from 'react-icons/fa6';
import { FaFacebookF } from 'react-icons/fa6';
import { CiInstagram } from 'react-icons/ci';
// import { FaTelegram } from 'react-icons/fa6';
// import { FaWhatsapp } from 'react-icons/fa6';
import { FaYoutube } from 'react-icons/fa';

import './LinkSocial.css';

function LinkSocial(props) {
  const { close = 'close-social' } = props;
  return (
    <div className={`header__list-social-media ${close}`}>
      <div className="header__social-link-item item-instagram">
        <Link
          to="https://www.instagram.com/sprzatanie_mieszkan_i_domow_?igsh=MWZ0MmY2azF4cGsxdQ=="
          title="instagram"
          alt="tiktok"
          target="_blank"
          className="header__instargam"
        >
          <CiInstagram className="social_logo instagram" />
        </Link>
      </div>
      <div className="header__social-link-item">
        <Link
          to="https://www.facebook.com/profile.php?id=61564879843254"
          target="_blank"
          alt="facebook"
          title="facebook"
          className="header_facebook"
        >
          <FaFacebookF className="social_logo" />
        </Link>
      </div>
      <div className="header__social-link-item">
        <Link
          to="https://www.tiktok.com/@czystydomwaw.czys?_t=8pc45Byqx97&_r=1"
          target="_blank"
          alt="tiktok"
          title="tiktok"
          className="header_facebook"
        >
          <FaTiktok className="social_logo" />
        </Link>
      </div>
      <div className="header__social-link-item">
        <Link
          to="https://youtube.com/@czystydomwawczystydomwaw?si=Zi1yWHum24JHcLiK"
          target="_blank"
          alt="telegram"
          title="youtube"
          className="header_facebook"
        >
          <FaYoutube className="social_logo" />
        </Link>
      </div>
      {/* <div className="header__social-link-item">
        <Link
          to="https://wa.me/48666561845"
          target="_blank"
          alt="whatsapp"
          title="whatsapp"
          className="header_facebook"
        >
          <FaWhatsapp className="social_logo" />
        </Link>
      </div> */}
    </div>
  );
}
export default LinkSocial;
