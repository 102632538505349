import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi2';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2> Tapicerka samochodowa w Warszawie</h2>
        <p>
          Wnętrze samochodu wymaga renowacji co około 60-120 tysięcy kilometrów,
          w zależności od materiału i warunków eksploatacji. Czyszczenie
          chemiczne samochodów w Krakowie to popularna usługa wśród specjalistów
          firmy Czysty Dom Waw, którzy na co dzień walczą z plamami na tkaninach
          i skórach naturalnych foteli samochodowych.
        </p>
        <h3>Jakie zanieczyszczenia można usunąć:</h3>
        <p>
          Firma Czysty Dom Waw walczy z plamami po napojach i jedzeniu,
          wytartymi plamami z ubrań oraz śladami zwierząt. Ogólnie rzecz biorąc,
          czyszczenie chemiczne wnętrza samochodu pozwala całkowicie przywrócić
          pierwotny kolor materiału, odświeżyć go, przywrócić jego fakturę i
          nadać mu świeżość. Po zakończeniu czyszczenia przez specjalistów w
          kabinie będzie zauważalny przyjemny aromat. Detailing przeprowadzamy
          wyłącznie przy użyciu atestowanych, wysokiej jakości środków
          czyszczących, które są całkowicie bezpieczne w kontakcie ze skórą
          ludzi i zwierząt.
        </p>
        <h3>Jak usunąć plamy z wnętrza samochodu</h3>
        <ul>
          <li>
            Czyszczenie siedzeń specjalnym odkurzaczem (czyszczenie chemiczne);
          </li>
          <li>
            Oczyszczenie trudno dostępnych miejsc roztworem dezynfekującym (w
            kanałach wentylacyjnych, pęknięciach, pod siedzeniami, szwach
            tapicerskich itp.);
          </li>
          <li>
            Obróbka siedzeń, tapicerki sufitowej i dywaników pianką, która ma
            działanie usuwające plamy i głęboko czyści;
          </li>
          <li>
            Pianę i środek dezynfekujący usuwa się za pomocą specjalnego
            odkurzacza na mokro pod wysokim ciśnieniem (pozwala uzyskać prawie
            suchą bazę);
          </li>
          <li>
            Nakładanie specjalnej substancji na tworzywa sztuczne i skórę w celu
            przywrócenia połysku i czystości.
          </li>
        </ul>
        <p>
          Dzięki temu w salonie przez długi czas panuje nieskazitelna czystość i
          przyjemny zapach. Zabieg ten można zamówić zarówno do samochodów ze
          skórzanym wnętrzem, jak i do samochodów z tekstylną tapicerką siedzeń.
          W zależności od materiału stosuje się różne środki i urządzenia
          czyszczące.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga2() {
  const obj = {
    mainLang: 'PL',
    path: '/en/usluga2',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga2;
