import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi2';
import { UslugiListEn } from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2> Car upholstery in Warsaw</h2>
        <p>
          The interior of the car requires renovation approximately every 60-120
          thousand kilometers, depending on the material and operating
          conditions. Dry cleaning of cars in Krakow is a popular service among
          "Czysty Dom Waw" specialists who deal with stains on fabrics and
          natural leather of car seats on a daily basis.
        </p>
        <h3>What contaminants can be removed:</h3>
        <p>
          "Czysty Dom Waw" fights against beverage and food stains, worn-out
          clothes stains and animal traces. In general, dry cleaning of the car
          interior allows you to completely restore the original color of the
          material, refresh it, restore its texture and make it fresh. After
          cleaning by specialists, a pleasant aroma will be noticeable in the
          cabin. We carry out detailing only using certified, high-quality
          cleaning agents that are completely safe in contact with human and
          animal skin.
        </p>
        <h3>How to remove stains from a car interior:</h3>
        <ul>
          <li>Cleaning seats with a special vacuum cleaner (dry cleaning);</li>
          <li>
            Cleaning hard-to-reach places with a disinfectant solution (in
            ventilation ducts, cracks, under seats, upholstery seams, etc.);
          </li>
          <li>
            Treatment of seats, ceiling upholstery and carpets with foam that
            has a stain-removing effect and deep cleans;
          </li>
          <li>
            Foam and disinfectant are removed using a special wet vacuum cleaner
            under high pressure (allows you to obtain an almost dry base);
          </li>
          <li>
            Applying a special substance to plastics and leather to restore
            shine and cleanliness.
          </li>
        </ul>
        <p>
          Thanks to this, the living room remains spotlessly clean and smells
          pleasant for a long time. This treatment can be ordered both for cars
          with a leather interior and for cars with textile seat upholstery.
          Depending on the material, different cleaning agents and devices are
          used.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiListEn />
        </div>
      </div>
    </div>
  );
}
function Usluga2() {
  const obj = {
    mainLang: 'En',
    path: '/usluga2',
    langP: 'English',
    langEng: 'Poland',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga2;
