import { HiOutlineMail } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import './MyEmail.css';

function MyEmail({ children, ...props }) {
  const {
    className = 'email__link',
    classnamelink = 'email__p',
    icons = <HiOutlineMail className="email__logo" />,
    email = 'czystydomwarszawa24@gmail.com',
  } = props;
  return (
    <Link
      title="email"
      className={className}
      to={'mailto:czystydomwarszawa24@gmail.com'}
    >
      <p className={classnamelink}>
        {icons} <span className="close-email">Poczta: </span>
        <span>{email}</span>
      </p>
    </Link>
  );
}
export default MyEmail;
