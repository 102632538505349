import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi8';
import { UslugiListEn } from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2>Carpet and rug cleaning in Warsaw</h2>
        <p>
          Dry cleaning of carpets and rugs is an important process that helps
          keep your home clean and hygienic. Over time, dust, dirt, animal hair
          and other contaminants accumulate in the fibers and cannot always be
          removed with daily cleaning. Professional cleaning effectively removes
          these particles, improving indoor air quality. Carpets require
          periodic cleaning because they accumulate dust, dirt, pet hair and
          dander during use. Czysty Dom Waw makes this process easier by
          offering on-site carpet cleaning services. This means you won't have
          to take a large rug to wash yourself, carry it across the landing, and
          so on.
        </p>
        <h3>Dry cleaning of carpets of any complexity:</h3>
        <ul>
          <li>
            Owners of such coatings encounter various types of contamination.
            These can be stains from food and drinks, pet urine or dirt from
            shoes. It is wise to clean every few months to reduce the number of
            dust mites and minimize the risk of allergies in children and
            adults.
          </li>
          <li>
            On-site carpet dry cleaning does not waste the customer's time. The
            process of soaking, surface treatment, vacuuming dirt and final
            washing does not take much time. This can be done at any time of the
            year and in any weather.
          </li>
        </ul>
        <p>
          Regular dry cleaning of carpets not only extends their life, but also
          helps maintain the aesthetic appearance of the interior. Cleaned
          carpets look fresh, retain their color intensity and create a cozy
          atmosphere in your home or office.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiListEn />
        </div>
      </div>
    </div>
  );
}
function Usluga8() {
  const obj = {
    mainLang: 'En',
    path: '/usluga8',
    langP: 'English',
    langEng: 'Poland',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga8;
