import HeaderMain from '../Header/Header';
import TopHeader from '../TopHeader/TopHeader';

function Header({ obj }) {
  return (
    <>
      <TopHeader obj={obj} />
      <HeaderMain />
    </>
  );
}
export default Header;
