import { useState } from 'react';
import './Question.css';
import { IoChevronDownOutline } from 'react-icons/io5';

function Question() {
  const [isVisible, setVisible] = useState(true);
  const [isVisible2, setVisible2] = useState(true);
  const [isVisible3, setVisible3] = useState(true);
  const [isVisible4, setVisible4] = useState(true);
  const [isVisible5, setVisible5] = useState(true);
  const [isVisible6, setVisible6] = useState(true);
  const [isVisible7, setVisible7] = useState(true);
  const [isVisible8, setVisible8] = useState(true);
  const [isVisible9, setVisible9] = useState(true);
  const handleVector = () => {
    setVisible(!isVisible);
  };
  const handleVector2 = () => {
    setVisible2(!isVisible2);
  };
  const handleVector3 = () => {
    setVisible3(!isVisible3);
  };
  const handleVector4 = () => {
    setVisible4(!isVisible4);
  };
  const handleVector5 = () => {
    setVisible5(!isVisible5);
  };
  const handleVector6 = () => {
    setVisible6(!isVisible6);
  };
  const handleVector7 = () => {
    setVisible7(!isVisible7);
  };
  const handleVector8 = () => {
    setVisible8(!isVisible8);
  };
  const handleVector9 = () => {
    setVisible9(!isVisible9);
  };

  return (
    <>
      <div className="container">
        <div className="question">
          <div className="question__title">
            <span>Często zadawane pytania</span>
          </div>
          <div className="question__items">
            <div className="question__item">
              <div onClick={handleVector} className="question__text">
                <span>W jaki sposób meble są czyszczone chemicznie?</span>
                <IoChevronDownOutline
                  onClick={handleVector}
                  className={
                    isVisible ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div className={isVisible ? 'response-none' : 'response'}>
                Sprzątanie odbywa się bezpośrednio w Twoim domu. Nasz
                specjalista przyjeżdża z całym niezbędnym sprzętem. Jedyne,
                czego potrzebuje, to dostęp do gniazdka i woda (najlepiej
                ciepła). Następnie następuje wycena produktu, ocena stopnia
                zabrudzenia oraz dobór środków chemicznych niezbędnych do
                skutecznego czyszczenia. Pierwszym krokiem jest usunięcie
                trudnych plam wszelkiego rodzaju odplamiaczami. Następnie
                rozpoczyna się proces namaczania produktu specjalną kompozycją
                detergentu do głębokiego czyszczenia, wnikającą głęboko w
                strukturę produktu. Następnie nakłada się wysokopieniący środek
                czyszczący, który usuwa brud powierzchniowy, brud i plamy z
                tapicerki. Kilka minut po aplikacji następuje mechaniczne
                działanie na szczególnie zabrudzone miejsca pędzlami o różnej
                twardości. Następnie zużytą kompozycję detergentową zbiera się
                za pomocą odkurzacza i dokładnie spłukuje wodą, aż do
                całkowitego usunięcia wszelkich pozostałych zanieczyszczeń i
                chemikaliów. Po czyszczeniu produkt pozostaje lekko wilgotny.
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector2} className="question__text">
                <span>Ile kosztuje wizyta u specjalisty?</span>
                <IoChevronDownOutline
                  onClick={handleVector2}
                  className={
                    isVisible2 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div
                className={
                  isVisible2 ? 'response-none-small' : 'response-small'
                }
              >
                Wizyta specjalisty na terenie miasta jest bezpłatna. Za
                sprzątanie zapłacisz dokładnie tyle, ile jest podane w cenniku.
                Wyjazd specjalisty poza miasto należy omówić indywidualnie.
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector3} className="question__text">
                <span>
                  Czy gwarantujecie, że wszystkie plamy zostaną usunięte?
                </span>
                <IoChevronDownOutline
                  onClick={handleVector3}
                  className={
                    isVisible3 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div className={isVisible3 ? 'response-none' : 'response'}>
                Niestety, nie możemy zagwarantować 100% usunięcia wszystkich
                plam i nieprzyjemnych zapachów, ponieważ wpływa na to wiele
                czynników: rodzaj zabrudzenia, jego ilość, czas, przez jaki
                znajdowało się na powierzchni, temperatura oddziaływania oraz
                próby samodzielnego czyszczenia. Niektóre plamy, takie jak krew,
                tłuszcz czy klej, mogą pozostać, a źródło zapachu może znajdować
                się głęboko w materiale. Jednak zawsze dokładamy wszelkich
                starań, aby osiągnąć jak najlepszy możliwy rezultat i zrobić
                wszystko, co w naszej mocy.
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector4} className="question__text">
                <span>Jak długo trwa sprzątanie?</span>
                <IoChevronDownOutline
                  onClick={handleVector4}
                  className={
                    isVisible4 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div
                className={
                  isVisible4 ? 'response-none-small' : 'response-small'
                }
              >
                Proces czyszczenia chemicznego sofy/materaca trwa średnio około
                2 godzin przy średnim poziomie zabrudzenia. Wszystko zależy od
                materiału i struktury tkaniny, a także stopnia zabrudzenia.
                Możemy wysuszyć meble w dogodnym dla Ciebie terminie. Po
                czyszczeniu produkt pozostaje lekko wilgotny i wymaga
                wyschnięcia. Zimą 10-12 godzin. Latem – 6-8 godzin. Możesz
                przyspieszyć proces suszenia zlecając nam suszenie mebli po
                czyszczeniu. Suszenie zajmie 2 godziny.
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector5} className="question__text">
                <span>Kiedy mogę zacząć stosować produkt?</span>
                <IoChevronDownOutline
                  onClick={handleVector5}
                  className={
                    isVisible5 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div className={isVisible5 ? 'response-none' : 'response'}>
                <p>
                  {' '}
                  Czyszczenie jest mokre, więc suszenie po czyszczeniu zajmuje
                  trochę czasu. Zimą z reguły w mieszkaniach/domach z dobrym
                  ogrzewaniem tapicerka schnie średnio 10-12 godzin po
                  czyszczeniu. Latem – 6-8 godzin. Należy również rozumieć, że w
                  zależności od materiału suszenie może trwać kilka godzin
                  dłużej.{' '}
                </p>
                <p>
                  Dotyczy to zwłaszcza produktów wykonanych z naturalnej
                  bawełny. Ponadto suszenie wszystkich rodzajów materacy po
                  czyszczeniu zajmuje około jednego dnia.
                </p>
                <p>
                  Aby przyspieszyć proces suszenia, wystarczy dobrze
                  przewietrzyć pomieszczenie. Jeśli chcesz skorzystać z produktu
                  tego samego dnia, możesz zamówić u nas suszenie wymuszone, a
                  całkowite wyschnięcie zajmie około dwóch godzin.
                </p>
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector6} className="question__text">
                <span>Jakich środków chemicznych i sprzętu używasz?</span>
                <IoChevronDownOutline
                  onClick={handleVector6}
                  className={
                    isVisible6 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div className={isVisible6 ? 'response-none' : 'response'}>
                Posiadamy dość szeroki wybór wszelkiego rodzaju sprzętu i
                środków chemicznych do różnego rodzaju materiałów. Przeważnie od
                niemieckiego producenta Karcher. Korzystamy z profesjonalnego
                odkurzacza myjącego Karcher Puzzi 10/1. Główną chemią jest
                Karcher RM 760 i RM 764 (środek do czyszczenia wykładzin
                tekstylnych i mebli tapicerowanych za pomocą odkurzacza). Jest
                to profesjonalna hipoalergiczna substancja chemiczna posiadająca
                wszelkie certyfikaty zgodności i całkowicie nieszkodliwa dla
                ludzi i zwierząt. Również szeroka gama najlepszych odplamiaczy
                światowych producentów Koch, Kiehl, Grass, Karpars, Chemspec.
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector7} className="question__text">
                <span>Jak często meble należy czyścić chemicznie?</span>
                <IoChevronDownOutline
                  onClick={handleVector7}
                  className={
                    isVisible7 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div className={isVisible7 ? 'response-none' : 'response'}>
                Zaleca się czyszczenie chemiczne mebli tapicerowanych i dywanów
                nie częściej niż raz na sześć miesięcy/rok. To właśnie w tym
                okresie meble zbierają kurz, brud, sierść zwierząt, a także
                powstają różne plamy. W końcu nie ma dnia, w którym nie
                przesiedzimy choćby 5 minut na ulubionej sofie lub krześle.
                Zawsze lepiej jest wyeliminować wszystkie „problemy” bez zwłoki,
                zanim zdążą się wchłonąć i wyschnąć. Daje to 95% szans, że po
                czyszczeniu nie zostaną po nich żadne ślady. Nie należy jednak
                nadużywać czystości. Zbyt częste czyszczenie może prowadzić do
                skrócenia żywotności produktu, utraty jego wyglądu, powstania
                zużycia i blaknięcia kolorów. Nie pierzesz swojej ulubionej
                koszulki kilka razy dziennie, prawda?
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector8} className="question__text">
                <span>
                  O której porze roku najlepiej wykonywać czyszczenie chemiczne?
                </span>
                <IoChevronDownOutline
                  onClick={handleVector8}
                  className={
                    isVisible8 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div
                className={
                  isVisible8 ? 'response-none-small' : 'response-small'
                }
              >
                Jeśli chodzi o meble do czyszczenia na sucho, nie ma związku z
                porą roku. Zimą z reguły w mieszkaniach/domach z dobrym
                ogrzewaniem tapicerka schnie średnio 10-12 godzin po
                czyszczeniu. Latem – 6-8 godzin. Jeśli chodzi o czyszczenie
                chemiczne samochodów, z pewnością lepiej jest to robić latem,
                ponieważ... suszenie samochodu zimą jest niezwykle
                problematyczne. Ponadto zimą wnętrze samochodu jest narażone na
                maksymalne zanieczyszczenie z powodu dużych ilości opadów.
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector9} className="question__text">
                <span>Jakie są etapy ekstrakcyjnego czyszczenia mebli?</span>
                <IoChevronDownOutline
                  onClick={handleVector9}
                  className={
                    isVisible9 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div className={isVisible9 ? 'response-none' : 'response'}>
                <p>
                  Czyszczenie ekstrakcyjne mebli na mokro składa się z
                  następujących etapów:
                </p>
                <ul>
                  <li>
                    Ocena stopnia zabrudzenia, identyfikacja materiału, dobór
                    środków czystości.
                  </li>
                  <li>
                    Wstępne spryskiwanie środkiem do czyszczenia mebli. Ten krok
                    służy do rozpuszczenia zanieczyszczeń.
                  </li>
                  <li>
                    Szczotkowanie. Niektóre tkaniny wymagają szczotkowania.
                    Należy pamiętać, że nie wszystkie tkaniny mogą być
                    traktowane szczotką.
                  </li>
                  <li>
                    Podstawowe czyszczenie tapicerki. Podczas tego etapu
                    tapicerka jest płukana za pomocą maszyny ekstrakcyjnej
                    Karcher. Podczas tego etapu usuwana jest większość
                    zanieczyszczeń.
                  </li>
                  <li>
                    Ostatnim etapem jest płukanie tkaniny meblowej czystą wodą.
                    Celem tego kroku jest usunięcie resztek brudu i środków
                    chemicznych.
                  </li>
                  <li>
                    Suszenie. Odbywa się w sposób naturalny i trwa około 10-15
                    godzin.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Question;
