import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import foto from '../../../img/about/about.jpg';
import './About.css';
import WhatsApp from '../../WhatsUp/WhatsappLink';

function AboutTable() {
  return (
    <div className="about">
      <div className="about-title">
        <h1>About Us</h1>
      </div>
      <div className="container about-container">
        <div className="about__content">
          <div className="about__content-text">
            <h2>
              Dear Customers, we are pleased to welcome you to our website!
            </h2>
            <p>
              <strong>"Czysty Dom Waw"</strong> is a leading company in the
              cleaning services industry, offering high-quality cleaning to
              commercial and private clients. Founded to provide the highest
              standards of cleanliness, the company quickly built a reputation
              for professionalism and attention to detail. Each Samurai Dry
              Cleaning employee undergoes thorough training to ensure effective
              and safe cleaning using modern equipment and ecological cleaning
              products.
            </p>
            <div className="about__content-img">
              <img src={foto} alt="О нас" />
            </div>
            <p>
              Softclean's basic philosophy is to provide maximum comfort and
              customer satisfaction. The company offers an individual approach
              to each order, developing special cleaning plans that take into
              account all the wishes and needs of customers. This ensures not
              only cleanliness, but also a healthy atmosphere in the rooms.
            </p>
            <p>
              Our company offers a wide range of services such as surface
              cleaning, stain removal, odor removal, disinfection, vacuuming and
              much more. Each service is tailored to the individual needs of the
              client to ensure the best possible results.
            </p>
            <p>
              One of the main advantages of Czysty Dom Waw is flexibility and
              speed of response to customer needs. The company ensures quick
              execution of orders at a time convenient for customers, which
              helps minimize inconvenience and interruptions in work. Samurai
              Dry Cleaning constantly improves its cleaning methods and
              technologies, introducing modern solutions to increase the
              efficiency and quality of its services.
            </p>
            <p>
              <strong>Czysty Dom Waw </strong> strives to become a leader in the
              cleaning industry by offering innovative and ecological solutions
              to its clients. Cooperation with Czysty Dom Waw is a guarantee of
              impeccable cleanliness and a high level of service.
            </p>
            <p>We operate in Warsaw and the surrounding area.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function About() {
  const obj = {
    mainLang: 'En',
    path: '/about',
    langP: 'English',
    langEng: 'Poland',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <AboutTable />
        <WhatsApp />
        <Footer />
      </div>
    </>
  );
}
export default About;
