import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/foto13.jpg';
import quilon2 from '../../../img/articles/article1.2.jpg';
import WhatsApp from '../../WhatsUp/WhatsappLink';
import { useProject } from '../../../tools/ProviderContext';

export function Cataloge() {
  const [isScroll, setScroll] = useState(true);
  const { notScrollLineUp } = useProject();

  useEffect(() => {
    if (isScroll) {
      window.scroll({ top: -100, behavior: 'smooth' });
      setScroll(false);
    }
  }, [isScroll]);

  const toggle = () => {
    setScroll(true);
  };

  return (
    <div className="catalogs">
      <h3>Catalog</h3>
      <ul>
        <li
          onClick={() => {
            toggle();
            notScrollLineUp();
          }}
        >
          <NavLink
            className={({ isActive }) => (isActive ? 'activelist' : '')}
            to="/en/article1"
          >
            <span>Washing mattresses</span>
          </NavLink>
        </li>
        <li
          onClick={() => {
            toggle();
            notScrollLineUp();
          }}
        >
          <NavLink
            className={({ isActive }) => (isActive ? 'activelist' : '')}
            to="/en/article2"
          >
            <span>Washing carpets and rugs</span>
          </NavLink>
        </li>
        <li
          onClick={() => {
            toggle();
            notScrollLineUp();
          }}
        >
          <NavLink
            className={({ isActive }) => (isActive ? 'activelist' : '')}
            to="/en/article3"
          >
            Washing furniture upholstery
          </NavLink>
        </li>
        <li
          onClick={() => {
            toggle();
            notScrollLineUp();
          }}
        >
          <NavLink
            className={({ isActive }) => (isActive ? 'activelist' : '')}
            to="/en/article4"
          >
            Washing curtains, curtains and blinds
          </NavLink>
        </li>
        <li
          onClick={() => {
            toggle();
            notScrollLineUp();
          }}
        >
          <NavLink
            className={({ isActive }) => (isActive ? 'activelist' : '')}
            to="/en/article5"
          >
            Washing car upholstery
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Washing furniture upholstery Warsaw</h2>

            <p>
              Washing upholstered furniture is one of the most important hygiene
              activities in our homes and workplaces. Upholstered furniture is
              not only a place to relax, but often also serves as a work or
              dining area. Therefore, they accumulate a lot of dirt, bacteria
              and mites that may have a negative impact on our health.
            </p>
            <p>
              One of the most important reasons for regularly washing
              upholstered furniture is to remove dirt from it. Upholstered
              furniture, especially in light colors, quickly starts to look
              dirty and damaged. Stains such as food stains, oil stains or dried
              food remains are very difficult to remove from the upholstery
              surface. Regular washing will help remove these stains and restore
              the furniture to its original appearance.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Another important reason for washing upholstered furniture is to
              prevent the growth of bacteria and fungi. If dirt and moisture get
              on the surface of upholstered furniture, fungi and bacteria may
              multiply, which may cause diseases and allergies. Washing
              upholstered furniture helps remove these microorganisms, which in
              turn has a positive effect on our health.
            </p>
            <p>
              The third reason for washing upholstered furniture is to get rid
              of dust mites. Dust mites are small organisms that live in dust
              and dirt on furniture surfaces. They cause allergies in many
              people, especially those who have breathing problems. Regularly
              washing upholstered furniture will help get rid of dust mites,
              which in turn will improve the air quality in our homes.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              To sum up, washing upholstered furniture is important to maintain
              hygiene in our homes and workplaces. It helps remove dirt,
              bacteria and mites that can negatively affect our health. Regular
              washing of upholstered furniture allows you to maintain its
              appearance and quality for a long time.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article1() {
  const obj = {
    mainLang: 'En',
    path: '/article3',
    langP: 'English',
    langEng: 'Poland',
  };
  return (
    <div className="body_wrapper">
      <Header obj={obj} />

      <Quilon />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Article1;
