import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiChevronUp } from 'react-icons/fi';

import './Buttons.css';

function ButtomsLang({ obj }) {
  const { mainLang = '', path = '/', langP = '', langEng = '' } = obj;
  const [isLang, setLang] = useState(true);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  const closeLang = () => {
    setLang(true);
  };
  return (
    <>
      <div
        onClick={closeLang}
        className={isLang ? 'hidden' : 'back-drop'}
      ></div>
      <div className="group">
        <div>
          <div className="lang__vector-group" onClick={changeLang}>
            <span>{mainLang}</span>
            <FiChevronUp className={isLang ? 'vectorup' : 'none'} />
          </div>
        </div>
        <div className={isLang ? 'change-lang-unvisible' : 'change-lang'}>
          <span onClick={closeLang} className="change-lang-span">
            {langP}
          </span>
          <Link to={path}>
            <span onClick={closeLang} className="change-lang-span">
              {langEng}
            </span>
          </Link>
        </div>

        {/* <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>EN</span>
            <FiChevronUp className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang ? <Link to="/about">PL</Link> : <Link to="/about">RU</Link>}
          </ButtonLang>
        </Lang> */}
      </div>
    </>
  );
}
export default ButtomsLang;
