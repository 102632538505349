import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi6';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2> Pranie chemiczne wózków i fotelików samochodowych w Warszawie</h2>
        <p>
          Jedną z najpopularniejszych usług jest czyszczenie chemiczne wózków
          dziecięcych i fotelików samochodowych w Warszawie. Rozumiemy, że ważne
          jest dla Ciebie utrzymanie czystości i bezpieczeństwa Twojego dziecka,
          dlatego korzystamy z najnowocześniejszych technologii i bezpiecznych
          receptur, aby osiągnąć wspaniałe rezultaty.
        </p>
        <h3>Nasze zalety:</h3>
        <ul>
          <li>
            Na pierwszym miejscu jest nieszkodliwość: gwarantujemy, że wszystkie
            stosowane przez nas chemikalia i metody są całkowicie nieszkodliwe.
            Nasi rzemieślnicy mają duże doświadczenie w pracy z produktami dla
            dzieci i rozumieją, jak konieczne jest unikanie szkodliwego wpływu
            na zdrowie dzieci. Stosujemy neutralne detergenty, a także generator
            pary, który zabija przede wszystkim bakterie i mikroorganizmy bez
            użycia środków chemicznych.
          </li>
          <li>
            skuteczne usuwanie plam i zapachów: Nasi specjaliści wyposażeni są w
            nowoczesny sprzęt i profesjonalne produkty, które skutecznie usuwają
            nawet najtrudniejsze plamy i nieprzyjemne zapachy. Cały proces
            czyszczenia odbywa się ręcznie. To nie jest banalne mycie wózków na
            myjni samochodowej przy użyciu Karchera, ale żmudna praca fizyczna.
            W wyniku czyszczenia Twój wózek lub fotelik samochodowy będzie nie
            tylko nieskazitelnie czysty, ale także będzie ładnie pachniał.
          </li>
          <li>
            Długotrwała konserwacja rzeczy: Technologia przetwarzania nie tylko
            zapewnia świeżość produktów, ale także wydłuża ich żywotność.
            Podczas procesu czyszczenia produkt jest całkowicie demontowany.
            Starannie podchodzimy do każdego szczegółu, zwracając uwagę na każdą
            fałdę i szew, aby zachować żywe kolory, zapobiec zużyciu i zachować
            optymalne standardy higieny. Rama, koła oraz wszystkie plastikowe
            elementy podwozia są dokładnie myte. Montaż odbywa się ściśle według
            instrukcji producentów, aby uniknąć ewentualnych traumatycznych
            sytuacji podczas pracy.
          </li>
          <li>
            Profesjonalny zespół: Nasi eksperci od czyszczenia fotelików
            samochodowych mają szerokie umiejętności w pracy z produktami dla
            dzieci. Przez nasze ręce przeszło setki modeli wiodących światowych
            producentów, takich jak Cybex, Bair, Anex, Espiro, Carrello, El
            Camino, KinderKraft, Lionelo itp. Znamy wszystkie zawiłości analizy
            i specyfikę czyszczenia każdego z nich tworzywo. Przywiązujemy wagę
            do szczegółów, sumiennie wykonujemy swoją pracę i jesteśmy gotowi
            zapewnić Twojemu Maluszkowi maksymalny komfort i czystość.
          </li>
        </ul>
        <p>
          Nie zapominaj, że czyszczenie chemiczne fotelików samochodowych i
          wózków dziecięcych to przede wszystkim zdrowie Twojego dziecka.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga6() {
  const obj = {
    mainLang: 'PL',
    path: '/en/usluga6',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga6;
