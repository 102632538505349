import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi4';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2> Pranie materaców w Warszawie</h2>
        <p>
          Niezależnie od tego, jak bardzo staramy się utrzymać materace w
          czystości, z biegiem czasu materiał traci swój pierwotny kolor i ulega
          zabrudzeniu. Nikt nie jest bezpieczny przed rozlaniem kawy, herbaty
          czy wina w łóżku. Firma Czysty Dom Waw oferuje pilne oczyszczenie
          materaca z zabrudzeń przy użyciu profesjonalnego sprzętu i
          ekologicznych produktów z serii professional.
        </p>
        <h3>Dlaczego czyszczenie chemiczne materacy jest konieczne?</h3>
        <p>
          Podczas użytkowania powierzchnia materaca pochłania cząsteczki potu,
          rozlanych płynów, zapachy jedzenia i wiele innych. Ponadto do tkaniny
          dostają się mikroorganizmy, roztocza i łupież. Wszystko to wymaga
          okresowego czyszczenia. Nie można tego jednak zrobić za pomocą
          domowych środków czyszczących. Wyobraź sobie, ile potu wchłania
          tkanina materaca (szczególnie w gorącym okresie letnim). Przed
          sierścią, sierścią zwierząt, a zwłaszcza kurzem nie można się
          uchronić. Czyszczenie materaca na sucho jest również obowiązkowe dla
          dziecka, aby zapobiec rozwojowi reakcji alergicznej na roztocza.
        </p>
        <h3>
          Czyszczenie materacy w domu pozwala rozwiązać kilka problemów na raz:
        </h3>

        <ul>
          <li>Usuń nieprzyjemny zapach z materaca;</li>
          <li>Oczyść go z plam;</li>
          <li>
            Obróbka siedzeń, tapicerki sufitowej i dywaników pianką, która ma
            działanie usuwające plamy i głęboko czyści;
          </li>
          <li>
            Usuń mikroorganizmy, kurz, włosy (za pomocą odkurzacza i specjalnego
            środka czyszczącego)
          </li>
        </ul>
        <p>
          W efekcie pracy mobilnej ekipy sprzątającej Czysty Dom Waw zyskasz
          zdrowy, spokojny sen na miękkim, czystym, świeżym materacu.
          Jednocześnie nie musisz nigdzie zabierać mebli – wszystko zrobimy u
          Ciebie w domu, szybko i sprawnie!
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga4() {
  const obj = {
    mainLang: 'PL',
    path: '/en/usluga4',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga4;
