import './Important.css';
import expert from '../../../img/important/expert.png';
import quality from '../../../img/important/quality-control.png';
import warning from '../../../img/important/profile.png';
import burning from '../../../img/important/burning.png';

function Important() {
  return (
    <div className="main__important">
      <h2 className="main-important-h2">
        <span>Why us!</span>
      </h2>

      <div className="main-important-blocks">
        <div className="main-important-block">
          <img src={burning} alt="expert" className="expert" />
          <h3>Fast deadlines</h3>
          <p>
            We will come to wash your furniture upholstery tomorrow or even
            today at a time convenient for you.
          </p>
        </div>
        <div className="main-important-block">
          <img src={expert} alt="expert" className="expert" />
          <h3>Professionalism</h3>
          <p>
            We approach the implementation of tasks responsibly and ensure
            effective cooperation with our clients and partners.
          </p>
        </div>
        <div className="main-important-block">
          <img src={quality} alt="expert" className="expert" />
          <h3>Quality</h3>
          <p>
            All chemicals we use are completely harmless to humans and animals,
            have all the necessary certificates of compliance, and our
            professional equipment from leading brands such as Karcher (Germany)
            guarantees high quality of work.
          </p>
        </div>
        <div className="main-important-block">
          <img src={warning} alt="expert" className="expert" />
          <h3>Individual approach</h3>
          <p>
            We provide an individual approach to each client, carefully
            analyzing their needs and proposing optimal solutions to fully meet
            their expectations.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Important;
