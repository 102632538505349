import './Services.css';
import main1 from '../../../img/Services/foto15.jpg';
import main2 from '../../../img/Services/car4.jpg';
import main3 from '../../../img/Services/armchair.png';
import main4 from '../../../img/Services/foto14.jpg';
import main5 from '../../../img/Services/cornerclean.png';
import main6 from '../../../img/Services/kids.png';
import main7 from '../../../img/Services/chair.jpg';
import main8 from '../../../img/Services/rug.jpg';
import main9 from '../../../img/Services/curtents.jpg';
import { Link } from 'react-router-dom';

function LinkMore(props) {
  const { path = '/' } = props;

  return (
    <Link {...props} to={path}>
      {' '}
      <span className="main-services-link">Szczegółowo...</span>
    </Link>
  );
}

function Services() {
  return (
    <>
      <div className="container container-main-service">
        <div className="main-title_uslugi">
          <p className="main-title_uslugi-p">Our services</p>
          <div className="main-title_uslugi-line"></div>
          <h1>
            Professional washing of sofas, armchairs, mattresses, strollers,
            corner sofas, curtains, blinds, rugs, carpets, car upholstery,
            furniture upholstery...
          </h1>
        </div>

        <div className="main__services-all">
          <div className="main__services-all-item">
            <img src={main1} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">Washing couches</h2>
              <LinkMore path={'/en/usluga1'} />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main2} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">Car upholstery</h2>
              <LinkMore path={'/en/usluga2'} />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main3} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">Washing armchairs</h2>
              <LinkMore path={'/en/usluga3'} />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main4} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">Washing mattresses</h2>
              <LinkMore path={'/en/usluga4'} />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main5} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">Corner washing</h2>
              <LinkMore path={'/en/usluga5'} />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main6} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">
                Dry cleaning of strollers and car seats
              </h2>
              <LinkMore
                path={'/en/usluga6'}
                style={{ position: 'relative', top: '-20px' }}
              />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main7} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">
                Cleaning leather upholstery
              </h2>
              <LinkMore
                path={'/en/usluga7'}
                style={{ position: 'relative', top: '-20px' }}
              />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main8} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">
                Washing carpets and rugs
              </h2>
              <LinkMore
                path={'/en/usluga8'}
                style={{ position: 'relative', top: '-20px' }}
              />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main9} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">
                Washing curtains, curtains and blinds
              </h2>
              <LinkMore
                path={'/en/usluga9'}
                style={{ position: 'relative', top: '-20px' }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Services;
