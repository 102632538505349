import { useState } from 'react';
import './Question.css';
import { IoChevronDownOutline } from 'react-icons/io5';

function Question() {
  const [isVisible, setVisible] = useState(true);
  const [isVisible2, setVisible2] = useState(true);
  const [isVisible3, setVisible3] = useState(true);
  const [isVisible4, setVisible4] = useState(true);
  const [isVisible5, setVisible5] = useState(true);
  const [isVisible6, setVisible6] = useState(true);
  const [isVisible7, setVisible7] = useState(true);
  const [isVisible8, setVisible8] = useState(true);
  const [isVisible9, setVisible9] = useState(true);
  const handleVector = () => {
    setVisible(!isVisible);
  };
  const handleVector2 = () => {
    setVisible2(!isVisible2);
  };
  const handleVector3 = () => {
    setVisible3(!isVisible3);
  };
  const handleVector4 = () => {
    setVisible4(!isVisible4);
  };
  const handleVector5 = () => {
    setVisible5(!isVisible5);
  };
  const handleVector6 = () => {
    setVisible6(!isVisible6);
  };
  const handleVector7 = () => {
    setVisible7(!isVisible7);
  };
  const handleVector8 = () => {
    setVisible8(!isVisible8);
  };
  const handleVector9 = () => {
    setVisible9(!isVisible9);
  };

  return (
    <>
      <div className="container">
        <div className="question">
          <div className="question__title">
            <span>Frequently asked questions</span>
          </div>
          <div className="question__items">
            <div className="question__item">
              <div onClick={handleVector} className="question__text">
                <span>How is furniture dry cleaned?</span>
                <IoChevronDownOutline
                  onClick={handleVector}
                  className={
                    isVisible ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div className={isVisible ? 'response-none' : 'response'}>
                Cleaning takes place directly in your home. Our specialist
                arrives with all the necessary equipment. The only thing it
                needs is access to a socket and water (preferably warm). Then
                the product is valued, the degree of contamination is assessed
                and the chemicals necessary for effective cleaning are selected.
                The first step is to remove difficult stains with all types of
                stain removers. Then the process of soaking the product with a
                special detergent composition for deep cleaning begins,
                penetrating deep into the structure of the product. A
                high-foaming cleaner is then applied to remove surface dirt,
                grime and stains from the upholstery. A few minutes after
                application, mechanical action is taken on particularly dirty
                places with brushes of various hardness. The used detergent
                composition is then collected using a vacuum cleaner and rinsed
                thoroughly with water until all remaining contaminants and
                chemicals are completely removed. After cleaning, the product
                remains slightly damp.
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector2} className="question__text">
                <span>How much does a visit to a specialist cost?</span>
                <IoChevronDownOutline
                  onClick={handleVector2}
                  className={
                    isVisible2 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div
                className={
                  isVisible2 ? 'response-none-small' : 'response-small'
                }
              >
                A specialist's visit in the city is free of charge. You will pay
                for cleaning exactly as stated in the price list. The
                specialist's trip outside the city should be discussed
                individually.
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector3} className="question__text">
                <span>Do you guarantee that all stains will be removed?</span>
                <IoChevronDownOutline
                  onClick={handleVector3}
                  className={
                    isVisible3 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div className={isVisible3 ? 'response-none' : 'response'}>
                Unfortunately, we cannot guarantee 100% removal of all stains
                and unpleasant odors, because this is influenced by many
                factors: the type of dirt, its amount, the time it was on the
                surface, the exposure temperature and attempts at self-cleaning.
                Some stains, such as blood, grease or glue, may remain and the
                source of the odor may lie deep in the material. However, we
                always strive to achieve the best possible result and do
                everything we can.
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector4} className="question__text">
                <span>How long does cleaning take?</span>
                <IoChevronDownOutline
                  onClick={handleVector4}
                  className={
                    isVisible4 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div
                className={
                  isVisible4 ? 'response-none-small' : 'response-small'
                }
              >
                The process of dry cleaning a sofa/mattress takes on average
                about 2 hours with an average level of dirt. It all depends on
                the material and structure of the fabric, as well as the degree
                of dirt. We can dry the furniture at a time convenient for you.
                After cleaning, the product remains slightly damp and requires
                drying. In winter 10-12 hours. In summer – 6-8 hours. You can
                speed up the drying process by letting us dry your furniture
                after cleaning. Drying will take 2 hours.
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector5} className="question__text">
                <span>When can I start using the product?</span>
                <IoChevronDownOutline
                  onClick={handleVector5}
                  className={
                    isVisible5 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div className={isVisible5 ? 'response-none' : 'response'}>
                <p>
                  Cleaning is wet, so it takes some time to dry after cleaning.
                  In winter, in apartments/houses with good heating, the
                  upholstery usually dries on average 10-12 hours after
                  cleaning. In summer – 6-8 hours. It should also be understood
                  that depending on the material, drying may take several hours
                  longer. This especially applies to products made of natural
                  cotton. Moreover, it takes about one day to dry all types of
                  mattresses after cleaning. To speed up the drying process,
                  just ventilate the room well. If you would like to use the
                  product on the same day, you can order force drying from us
                  and it will take approximately two hours to dry completely.
                </p>
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector6} className="question__text">
                <span>What chemicals and equipment do you use?</span>
                <IoChevronDownOutline
                  onClick={handleVector6}
                  className={
                    isVisible6 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div className={isVisible6 ? 'response-none' : 'response'}>
                We have quite a wide selection of all types of equipment and
                chemicals for various types of materials. Mostly from the German
                manufacturer Karcher. We use a professional Karcher Puzzi 10/1
                vacuum cleaner. The main chemicals are Karcher RM 760 and RM 764
                (a product for cleaning textile carpets and upholstered
                furniture using a vacuum cleaner). It is a professional
                hypoallergenic chemical substance with all compliance
                certificates and completely harmless to humans and animals. Also
                a wide range of the best stain removers from world producers
                Koch, Kiehl, Grass, Karpars, Chemspec.
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector7} className="question__text">
                <span>How often should furniture be dry cleaned?</span>
                <IoChevronDownOutline
                  onClick={handleVector7}
                  className={
                    isVisible7 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div className={isVisible7 ? 'response-none' : 'response'}>
                It is recommended to dry clean upholstered furniture and carpets
                no more than once every six months/year. It is during this
                period that furniture collects dust, dirt, animal hair, and
                various stains appear. After all, there is not a day that goes
                by when we do not spend at least 5 minutes on our favorite sofa
                or chair. It is always better to eliminate all "problems"
                without delay, before they have a chance to absorb and dry. This
                gives a 95% chance that there will be no traces left after
                cleaning. However, cleanliness should not be abused. Too
                frequent cleaning can lead to a shortened product life, loss of
                appearance, wear and tear and color fading. You don't wash your
                favorite T-shirt several times a day, right?
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector8} className="question__text">
                <span>What time of year is best for dry cleaning?</span>
                <IoChevronDownOutline
                  onClick={handleVector8}
                  className={
                    isVisible8 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div
                className={
                  isVisible8 ? 'response-none-small' : 'response-small'
                }
              >
                When it comes to dry cleaning furniture, it has nothing to do
                with the season. In winter, in apartments/houses with good
                heating, the upholstery usually dries on average 10-12 hours
                after cleaning. In summer – 6-8 hours. When it comes to dry
                cleaning cars, it is certainly better to do it in the summer
                because... drying the car in winter is extremely problematic.
                Moreover, in winter, the interior of the car is exposed to
                maximum pollution due to large amounts of rainfall.
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector9} className="question__text">
                <span>
                  What are the stages of extraction furniture cleaning?
                </span>
                <IoChevronDownOutline
                  onClick={handleVector9}
                  className={
                    isVisible9 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div className={isVisible9 ? 'response-none' : 'response'}>
                <p>
                  Wet extraction cleaning of furniture consists of the following
                  stages:
                </p>
                <ul>
                  <li>
                    Assessment of the degree of dirt, material identification,
                    selection of cleaning products.
                  </li>
                  <li>
                    Pre-spray with furniture cleaner. This step is used to
                    dissolve impurities.
                  </li>
                  <li>
                    {' '}
                    Brushing. Some fabrics require brushing. Please note that
                    not all fabrics can be brushed.
                  </li>
                  <li>
                    Basic upholstery cleaning. During this stage, the upholstery
                    is rinsed using a Karcher extraction machine. During this
                    stage, most of the impurities are removed.
                  </li>
                  <li>
                    The final stage is rinsing the furniture fabric with clean
                    water. The purpose of this step is to remove residual dirt
                    and chemicals.
                  </li>
                  <li>
                    Drying. It takes place naturally and lasts about 10-15
                    hours.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Question;
