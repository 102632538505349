import { useLayoutEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useProject } from '../../../tools/ProviderContext';
import './Nav.css';
import { useState } from 'react';
function Nav() {
  const { closeBurgerMenu, notScrollBtn, closeMenu } = useProject();
  const [isMenu800, setMenu800] = useState(false);
  useLayoutEffect(() => {
    if (window.innerWidth < 1070) {
      setMenu800(false);
    } else {
      setMenu800(true);
    }
  }, [isMenu800]);
  return (
    <>
      {isMenu800 ? (
        <nav className="menu">
          <ul className={'menu__list'}>
            <li className={'menu__item'}>
              <NavLink
                to="/en/main"
                className="menu__link"
                onClick={() => {
                  closeBurgerMenu();
                }}
              >
                Main
              </NavLink>
            </li>
            <li className={'menu__item'}>
              <NavLink
                to="/en/about"
                onClick={() => {
                  closeBurgerMenu();
                }}
                className="menu__link"
              >
                About us
              </NavLink>
            </li>
            <li className={'menu__item'}>
              <NavLink
                to="/en/usluga"
                className="menu__link"
                onClick={() => {
                  closeBurgerMenu();
                }}
              >
                services
              </NavLink>
            </li>
            <li className={'menu__item'}>
              <NavLink
                to="/en/price"
                className="menu__link"
                onClick={() => {
                  closeBurgerMenu();
                }}
              >
                Price
              </NavLink>
            </li>
            {/* <li className={'menu__item'}>
              <NavLink
                to="/pytanie"
                className="menu__link"
                onClick={() => {
                  closeBurgerMenu();
                }}
              >
                Pytanie
              </NavLink>
            </li> */}
            <li className={'menu__item'}>
              <NavLink
                to="/en/article1"
                className="menu__link"
                onClick={() => {
                  closeBurgerMenu();
                }}
              >
                Blog
              </NavLink>
            </li>
            <li className={'menu__item'}>
              <NavLink
                to="/en/contact"
                className="menu__link"
                onClick={() => {
                  closeBurgerMenu();
                }}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
      ) : (
        <nav className="menu">
          <ul className={'menu__list'}>
            <li className={'menu__item'}>
              <NavLink
                to="/en/main"
                className="menu__link"
                onClick={() => {
                  closeBurgerMenu();
                  notScrollBtn();
                  closeMenu();
                }}
              >
                Main
              </NavLink>
            </li>
            <li className={'menu__item'}>
              <NavLink
                to="/en/about"
                onClick={() => {
                  closeBurgerMenu();
                  notScrollBtn();
                  closeMenu();
                }}
                className="menu__link"
              >
                About us
              </NavLink>
            </li>
            <li className={'menu__item'}>
              <NavLink
                to="/en/usluga"
                onClick={() => {
                  closeBurgerMenu();
                  notScrollBtn();
                  closeMenu();
                }}
                className="menu__link"
              >
                Services
              </NavLink>
            </li>
            <li className={'menu__item'}>
              <NavLink
                to="/en/price"
                className="menu__link"
                onClick={() => {
                  closeBurgerMenu();
                  notScrollBtn();
                  closeMenu();
                }}
              >
                Price
              </NavLink>
            </li>
            {/* <li className={'menu__item'}>
              <NavLink
                to="/pytanie"
                className="menu__link"
                onClick={() => {
                  closeBurgerMenu();
                  notScrollBtn();
                  closeMenu();
                }}
              >
                Pytanie
              </NavLink>
            </li> */}
            <li className={'menu__item'}>
              <NavLink
                to="/en/article1"
                className="menu__link"
                onClick={() => {
                  closeBurgerMenu();
                  notScrollBtn();
                  closeMenu();
                }}
              >
                Blog
              </NavLink>
            </li>
            <li className={'menu__item'}>
              <NavLink
                to="/en/contact"
                className="menu__link"
                onClick={() => {
                  closeBurgerMenu();
                  notScrollBtn();
                  closeMenu();
                }}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
}
export default Nav;
